#small-logo, #small-logo img {
  display: block;
  cursor: pointer;  
  width: 175px;
 }
 
 #small-logo {
   margin: 20px 0 40px 0;
 }

.mobile #small-logo {
  margin: 15px auto;
}

h3 {
  margin-top: 25px;
}

.mobile .title {    /* score grid title is removed for mobbile, it takes too much space otherwise */
  display: none;
}

.title {
  text-align: center;
  margin-top: -25px;
  margin-bottom: 30px;
}

.winner {
  color: white;
  text-align: center;
  margin: 20px;
}

/*DESKTOP buttons design*/

.buttons {                  /*First wrap container for button in InGame(Over)Control components*/
  margin: 0.5em auto;
  width: 380px;
}

/* The following two rules override bootstrap's row/col spacing */
.buttons .row {
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 8px;
}

.buttons .col {
  padding-left: 4px;
  padding-right: 4px;
}

/* Button style */

.btn {
  width: 100%;
  border: solid 1px #980c10;
  color: white;
  font-size: 18px;
  line-height: 2.0rem; /* This aligns the bingo fake button text */
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(222, 50, 42, 0.5)), to(rgb(98, 37, 40, 0.5)));
  text-shadow: 1px 1px rgba(0,0,0,0.8);
  text-decoration: none;
}

.btn:hover {
  color: white;
  box-shadow: 0 0 6px rgba(10,10,10,0.25);
}

.btn:disabled {
  box-shadow: none; /* No hover effet if button disabled */
}

/*Bingo button styles*/

.buttons input[type=checkbox] {
  display: none;
}

label.btn {
  margin-bottom: 0;
}

input[type=checkbox]:checked+label {
  background: -webkit-gradient(linear, left bottom, left top, from(rgb(222, 50, 42, 0.5)), to(rgb(98, 37, 40, 0.5)));
}

/*MOBILE buttons design*/

.mobile .buttons {
  width: 340px;
}

.mobile .btn {
  font-size: 16px;
}

/*CallPlayerToAction style*/

.desktop .call-player-to-action {
  margin-bottom: 15px;
}

.instruction-message {
  text-align: center;
  margin: 0.5em auto;
  padding: 0.5em 0;
  line-height: 1em;
  max-height: 3em;
  transition: max-height 0.3s ease 1.5s, padding 0.3s ease 1.5s, margin 0.3s ease 1.5s;
  overflow: hidden;
  font-size: 1.1em;
  font-weight: bold;
}

.instruction-message.hide {
  max-height: 0em;
  padding: 0;
  margin: 0;
  transition: max-height 0.3s ease 0s, padding 0.3s ease 0s, margin 0.3s ease 0s;
}

.instructions {
  margin-top: 60px;
}

/* Style for RATE US Buttons */

.cordova-ios .android-rating, .cordova-ios .rate-me .row.playmarket {
   display: none;
}

.cordova-android .app-store-rating, .cordova-android .rate-me .row.apple {
  display: none;
}

.web-platform .rate-me{
  display: none;
}