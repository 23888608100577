/*General table css*/

.table-bordered {                     
  table-layout: fixed;
  background-color: rgb(98,37,40, 0.4);
  text-shadow: 1px 1px rgba(0,0,0,0.8);
}

.table thead th {
  vertical-align: middle;
  border-bottom-style: inherit;
}

.move-header {
	width: 65px;
}

.table th {
	height: 57px;
	vertical-align: middle;
  text-align: center;
  padding: 0px;
}

.table td {
	vertical-align: middle;
  padding: 0.4rem;
}

.score-grid-cell td {
  border: none;
  margin: 0;
  padding: 0;
}

.score-grid-cell {
  width: 100%;
  text-align: center;
}

.player-turn.active {
  border: 4px solid white;
}

.score-grid-cell .word-cell {
  text-align: left;
}

.score-grid-cell .word-cell  .reaper {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding-left: 30px;
}

.score-cell {
  text-align: right;
}

.score-grid-cell td.score-cell {
  width: 30px;
}

.score-box {
  display: inline-block;
  border: 1px solid white;
  background-color: rgb(98,37,40, 0.3);
  height: 30px;
  width: 30px;
  border-radius: 5px;
  padding-top: 2px;
  text-align: center;
}

.bingo-cell {
  width: 100%;
}

.bingo {
  vertical-align: middle;
}

.total-score td {
  text-align: center;
}

/*MOBILE table css*/

.mobile .score-grid-table {
  font-size: 14px;
}

.mobile .playerNames {
  width: 61px;
  
  text-align: center;
  padding: 0px;
}

.mobile .score-grid-table td {
  padding: 0px 1px;
}

.mobile .move-row {
  font-weight: bold;
  background-color: rgb(98,37,40, 0.3);
  text-align: center;
}

.mobile .player-name {
  text-align: center;
}

.mobile table.score-grid-cell {
  margin: 6px 0;
  min-height: 30px;
}

.mobile .score-grid-cell .word-cell  .reaper {
  padding-left: 30px;
}
