#big-logo {
  width: 50%;
  margin: 30px auto;
  max-width: 350px;
  max-height: 115px;
  min-width: 250px;
  min-height: 50px;
  display: block;
}

@media screen and (max-width: 815px) {      /*Mobile version displays smaller logo*/
  div.logo {
    width: 300px;
    height: 60px;
  }
}

/*Design for LANGUAGE SELECT*/
.custom-select#language-select {    /*Untill internationalization is done, this element will remain hidden*/
  margin-bottom: 0;
}

.rules {
  margin-top: 20px;
}

.custom-select {
  width: 160px;
  height: 47px;
  margin: 19px;
  display: inline-block;
  border: solid 1px white;
  color: white;
  background-color: rgb(255, 255, 255, 0.2);
  font-size: 18px;
}

/*Design for "START BUTTON"*/

.start-btn-container {
  margin: 0 auto;
  width: 180px;
}

@media screen and (max-width: 815px) {
  .start-btn-container {
    margin-top: 20px;
  }
}

 /*Design for PLAYERS' NAMES INPUT lines*/
 /*DESKTOP PLAYERS' NAMES INPUT*/

.language-choice-container {
  text-align: center;
  float: none;
}

.sel-lang {
  margin-bottom: 0;
}

.player-names-choice-container {   /*Container for player name inputs*/
	text-align: center;
	float: none;
  margin: 20px;
}

.form-control.player-name {
 	width: 160px;
 	height: 47px;
	margin: 19px;
	display: inline-block;
	border: solid 1px white;
  color: white;
  background-color: rgb(255, 255, 255, 0.2);
  font-size: 18px;
}

input::-webkit-input-placeholder {
  color:  #980c10 !important;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:   #980c10 !important;
   opacity:  1;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:   #980c10 !important;
   opacity:  1;
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:   #980c10 !important;
}
input::-ms-input-placeholder { /* Microsoft Edge */
   color:   #980c10 !important;
}

input::placeholder { /* Most modern browsers support this now. */
   color:   #980c10 !important;
}

input:focus {
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.75), 0 0 8px rgba(255, 255, 255, 0.75) !important;
}

/*MOBILE PLAYERS' NAMES INPUT*/

@media screen and (max-width: 815px) {
  .form-control.player-name {
    margin: 6px;
    width: 216px;
  }
}

.should-resume {
  
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding: 2em;
}

.mobile .should-resume {

}

.should-resume p {
  text-align: center;
  font-size: 1.2em;
  text-shadow: 1px 1px rgba(0,0,0,1);
}

.should-resume .buttons {
  margin: 0.5em auto;
  width: 250px;
}

.mobile .should-resume .buttons {
  margin: 0.5em auto;
  width: 250px;
}

.should-resume .btn {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(222, 50, 42, 0.85)), to(rgb(98, 37, 40, 0.85)));

}

.should-resume .btn.faded {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(222, 50, 42, 0.65)), to(rgb(98, 37, 40, 0.65)));
  border: 0;
  margin-top: 1px;
}
